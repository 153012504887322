module.exports = [{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/gatsby-browser.js'),
      options: {"plugins":[],"layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1680/src/components/WebsiteLayout.jsx","postDestination":"Blog"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michael Perry","short_name":"Michael Perry","start_url":"/","background_color":"#FF000D","theme_color":"#FF000D","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48711915fbb70e3369e1e45c13bcefef"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst","options":{"cacheName":"google-fonts-webfonts","expiration":{"maxAgeSeconds":31536000,"maxEntries":30},"cacheableResponse":{"statuses":["200"]}}},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.michaelperryhairstudio.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceCategory","templateName":"ServiceCategory","recipe":"muse","tagName":"ServiceCategories","fishermanParentPageType":"Services","orderPath":"order","groupPath":"schedule.name","groupOrderPath":"schedule.order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1680/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_1"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceItem","templateName":"ServiceItem","recipe":"muse","tagName":"ServiceItems","fishermanParentPageType":"Services","orderPath":"order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1680/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_2"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"TeamMember","templateName":"TeamMember","recipe":"muse","fishermanParentPageType":"Team","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1680/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_3"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-locations/gatsby-browser.js'),
      options: {"plugins":[],"useStorage":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-animations/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MZ97M5VV","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
